
import { computed, onBeforeMount, h, PropType, defineComponent } from 'vue'
import { TableLayout } from '@/types/BasicComponent'
let tbid = 0

const baseStyle = {
  // fontSize: '18px',
  marginBottom: '5px',
  fontFamily: '宋体',
  fontSize: '16px',
  // fontWeight: '800',
  textAlign: 'center',
  // height: '30px',
  // padding: '10px 0'
  // width: '205px',
  // border: '1px solid #a6a9ad'
}

export default defineComponent({
  props: {
    layout: {
      type: Object as PropType<TableLayout>,
      required: true
    },
    renderLayout: {
      type: Boolean,
      default: false
    },
    customBaseStyle: Object,
    tableAttrs: {
      type: Object,
      default: {
        cellpadding: '0',
        cellspacing: '0',
      }
    }
  },
  setup(props) {
    const itemStyle = computed(() => Object.assign({}, baseStyle || {}, props.customBaseStyle))

    onBeforeMount(() => tbid ++)

    return () => {
      if (!props.renderLayout) return <div></div>
      return (
        <table 
          id={`exportTable-${tbid}`}
          class="__export-table"
          style={{
            borderCollapse: 'collapse',
            // tableLayout: 'fixed',
            wordBreak: 'break-all',
            wordWrap: 'break-word'
          }}
          {...props.tableAttrs}
        >
          {['thead', 'tbody', 'tfoot'].map((el) => (
            h(el, undefined, props.layout[el].map(row => (
              <tr>
                {
                  row.map(col => {
                    if (col.colSpan === 0 || col.rowSpan === 0) return
                    return h(
                      'td',
                      {
                        style: Object.assign({}, itemStyle.value, col.style),
                        colSpan: col.colSpan == null? 1: col.colSpan,
                        rowSpan: col.rowSpan == null? 1: col.rowSpan,
                        ...col.attr,
                        class: col.class
                      },
                      [col.render && col.render(col.data as any) || col.data] as any
                    )
                  })
                }
              </tr>
            ) as any))
          ))}
        </table>
      )
    }
  }
})
