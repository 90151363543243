
import ExportExcel from '@/components/ExportExcel/index'
import { computed, defineComponent, PropType, reactive, ref, toRef, toRefs, } from 'vue'
import { ColumnConfig, SpanMethod, TableColumns, TableLayout, TableLayoutCol, TableLayoutRow, TableLayoutSection } from '@/types/BasicComponent'

type ColumnConfigExtend = ColumnConfig & { _cs?: number, _rs?: number }

type TableColumnsExtend = ColumnConfigExtend[]

export default defineComponent({
  components: {
    // PrintTable: Print,
    ExportExcel
  },
  props: {
    tableData: {
      type: Array as PropType<AnyArray>,
      default: []
    },
    extraHeader: {
      type: Array as PropType<AnyArray>,
      default: []
    },
    tableColumns: {
      type: Array as PropType<TableColumns>,
      default: []
    },
    summaryData: {
      type: Array as PropType<AnyArray>,
      default: []
    },
    fileName: {
      type: String,
      default: ''
    },
    spanMethod: {
      type: Function as PropType<SpanMethod>
    }
  },
  setup(props) {

    const filterColumnTypes = ['selection', 'buttons']

    const tableWidth = 650

    const { tableData, summaryData, fileName } = toRefs(props)
    const printRef = ref()
    const exportRef = ref()
    const tableColumns = computed(() => props.tableColumns.filter(col => !filterColumnTypes.includes(String(col.type))))

    const doPrint = () => {
      printRef.value.preview()
    }

    const doExport = (cb?: Function) => {
      exportRef.value.export(cb)
    }

    // 递归计算出表格总列宽（针对多级表头的情况 https://element-plus.gitee.io/#/zh-CN/component/table#duo-ji-biao-tou）
    const calcColumnWidth = (columns: TableColumns) => {
      let widthSum = 0
      for (let column of columns) {
        if (column.columns) {
          widthSum += calcColumnWidth(column.columns)
        } else {
          const currentWidth = Number(((column.minWidth || column.width || '0') as string).replace('px', ''))
          widthSum += currentWidth
        }
      }
      return widthSum
    }
    
    const widthSum = computed(() => calcColumnWidth(tableColumns.value))

    // 多级表头的情况，计算打印列的colspan
    const columnTitleLayout = (columns: TableColumnsExtend, scaleTable = false /**是否根据限制的最大宽度缩放表格列宽 */) => {
      const result: any = []
      let maxDeep = -1
      let deep = -1
      const calcWrappedColumnLength = (currentCol: ColumnConfigExtend) => {
        deep ++
        if (deep > maxDeep) maxDeep = deep
        let counter = 0
        if (currentCol.columns) {
          for (let col of currentCol.columns) {
            counter += calcWrappedColumnLength(col)
          }
        }
        const colSpan = counter === 0? 1: counter
        currentCol._cs = colSpan
        deep --
        return colSpan
      }

      const transformColToLayout = (col: ColumnConfigExtend, deep: number) => {
        if (!result[deep]) {
          result[deep] = []
        }
        let width = Number(((col.minWidth || col.width || '0') as string).replace('px', ''))
        // 如果打印表格，需要根据打印的最大宽度缩放列宽
        if (scaleTable) {
          width = (width / widthSum.value) * tableWidth
        }  
        
        result[deep].push({
          colSpan: col._cs,
          rowSpan: col._rs || 1,
          data: col.label,
          style: {
            width: width + 'px'
          }
        })
      }

      let traverseDeep = -1
      const traverse = (cols: TableColumnsExtend) => {
        traverseDeep ++
        for (let col of cols) {
          if (!col.columns)
            col._rs = maxDeep - traverseDeep + 1
          transformColToLayout(col, traverseDeep)
          if (col.columns) 
            traverse(col.columns)
        }
        traverseDeep --
      }

      columns.forEach(col => calcWrappedColumnLength(col))
      traverse(columns)
      return result
    }

    const columnHeaders = computed(() => columnTitleLayout(tableColumns.value))
    let colSpanSum = 0
    columnHeaders.value[0]?.forEach((item: any) => {
      colSpanSum += item.colSpan || 1
    })
    const widthPerColSpan = tableWidth / colSpanSum
    const extraHeaders = computed(() => JSON.parse(JSON.stringify(props.extraHeader)))

    extraHeaders.value.forEach((row: any) => {
      row.forEach((col: any) => {
        col.style = col.style || {}
        col.style.width = (col.colSpan || 1)  * widthPerColSpan + 'px'
      })
    })

    const exportHeaderLayout = computed<TableLayoutSection>(() => [
      ...props.extraHeader,
      ...columnHeaders.value,
    ])

    const printHeaderLayout = computed<TableLayoutSection>(() => [
      ...extraHeaders.value,
      ...columnTitleLayout(tableColumns.value, true),
    ])

    // 把所有的表头数据展开成一维数组
    const flatColumns = (columns: TableColumnsExtend) => {
      const result: TableColumnsExtend = []
      const doFlat = (cols: TableColumnsExtend) => {
        for (let col of cols) {
          result.push(col)
          if (col.columns)
            doFlat(col.columns)
        }
      }
      doFlat(columns)
      return result
    }
    

    const bodyLayout = computed<TableLayoutSection>(() => {
      const dataRows = tableData.value
      .map(
        (rowData, index) => flatColumns(tableColumns.value)
          .filter(column => !column.columns)
          .map(column => {
            let data = rowData[column.prop as string]
            if (column.type === 'status') {
              const match = column.statusType.find(t => t.value == data)
              data = match? match.label: ''
            }
            if (column.type === 'index') {
              data = index + 1
            }
            if (column.type === 'render') {
              data = column.render({ row: rowData, $index: index })
            }

            let shouldPreventFormat = true
            if (!isNaN(Number(data)) && data != null) {
              shouldPreventFormat = false
            }
            if (column.preventFormat != null && column.preventFormat == true) shouldPreventFormat = true

            return {
              data,
              style: {textAlign: column.align},
              class: shouldPreventFormat? 'prevent-format-number': '',
            } as TableLayoutCol
          })
      )

      const summaryRows = summaryData.value.length > 0 ?
        [
          summaryData.value.map(data => {
            if (isNaN(Number(data))) return data
            return {data, style: {textAlign: 'right'}} as TableLayoutCol
          })
        ]: []

      if (props.spanMethod) {
        for (let i in dataRows) {
          const col = dataRows[i]
          for (let j in col) {
            const detail = col[j]
            const spanConfig = props.spanMethod({ row: props.tableData[i], column: {} as any, rowIndex: parseInt(i), columnIndex: parseInt(j) })
            detail.rowSpan = spanConfig.rowspan
            detail.colSpan = spanConfig.colspan
          }
        }
      }

      return dataRows.concat(summaryRows)
    })

    const exportTableLayout = computed<TableLayout>(() => {
      return {
        thead: exportHeaderLayout.value,
        tbody: bodyLayout.value,
        tfoot: []
      }
    })

    const printTableLayout = computed<TableLayout>(() => {
      return {
        thead: printHeaderLayout.value,
        tbody: bodyLayout.value,
        tfoot: []
      }
    })

    return {
      doPrint,
      doExport,
      fileName,
      exportTableLayout,
      printTableLayout,
      printRef,
      exportRef,
      tableWidth
    }
  }
})
