import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_export_excel = _resolveComponent("export-excel")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_export_excel, {
      ref: "exportRef",
      fileName: _ctx.fileName,
      tableLayout: _ctx.exportTableLayout,
      "base-style": {border: '.5px solid black'}
    }, null, 8, ["fileName", "tableLayout"])
  ]))
}