import TableLayout from '@/components/TableLayout/index.vue'
import { defineComponent, PropType } from 'vue'
import { TableLayout as ITableLayout } from '@/types/BasicComponent'

export default defineComponent({
  components: {
    TableLayout
  },
  props: {
    fileName: {
      type: String,
      default: ''
    },
    tableLayout: {
      type: Object as PropType<ITableLayout>,
      require: true
    },
    // 是否带格子
    excelFormat: {
      type: Boolean,
      default: true
    },
    baseStyle: Object
  },
  data() {
    return {
      renderLayout: false,
    }
  },
  methods: {
    export(cb?: Function) {
      this.renderLayout = true;
      this.$nextTick(() => {
        let tableHtml = `<html ${this.excelFormat? 'xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel"': ''} xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body>`
        const html = (this.$refs.tableRef as any).$el.outerHTML;
        tableHtml += html;
        tableHtml += "</body></html>";
        const excelBlob = new Blob([tableHtml], {
          type: "application/vnd.ms-excel"
        });
        const oa = document.createElement("a");
        oa.href = URL.createObjectURL(excelBlob);

        let fileName = this.fileName
        if (!fileName.endsWith('.xls') || !fileName.endsWith('.xlsx')) fileName = fileName + '.xls'
        oa.download = fileName;

        document.body.appendChild(oa);
        oa.click();
        this.$nextTick(() => {
          this.renderLayout = false
          cb?.()
        })
      })
    }
  },
  updated() {
    const tableRef = this.$refs.tableRef as any
    const preventFormatElements = tableRef.$el.querySelectorAll(`.prevent-format-number`) as HTMLElement[]
    Array.from(preventFormatElements).forEach((item) => {
      const itemStyle = item.getAttribute('style') as string;
      // 防止excel将数字格式化导致值溢出
      if (itemStyle.indexOf('mso-number-format') < 0) {
        item.setAttribute('style', itemStyle + ";mso-number-format:'\@';");
      }
    })

    const brElements = tableRef.$el.querySelectorAll(`br`) as HTMLElement[]
    Array.from(brElements).forEach(item => {
      const style = item.getAttribute('style')
      item.setAttribute('style', style || '' + ' mso-data-placement:same-cell;')
    })
  },
  render() {
    return <TableLayout style={{display: 'none'}} layout={this.tableLayout as ITableLayout} customBaseStyle={this.baseStyle} renderLayout={true} ref="tableRef" />
  }
})