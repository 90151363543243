import { BaseResponse, C6BaseResponse, C6PageRequest, C6PageResponse, PageRequest } from '@/types/API'
import request, { c6Request } from '@/utils/request'

// /** 获取原木入库信息 */
// export function getWoodIntoInfo(orderId: number) {
//   return request.get<BaseResponse>(`production/wood-into-info/${orderId}`)
// }

/** 获取原木入库信息 */
export function getWoodIntoInfo(orderId: number) {
  return c6Request.get<C6BaseResponse>(`backstage/production/getWoodIntoInfo`, { params: { orderId } })
}

// /** 获取旋切机设备信息 */
// export function getXqjDeviceInfo(orderId: number) {
//   return request.get<BaseResponse>(`production/xqj-device-info/${orderId}`)
// }

/** 获取旋切机设备信息 */
export function getXqjDeviceInfo(orderId: number) {
  return c6Request.get<C6BaseResponse>(`backstage/production/getXqjzDeviceInfo`, { params: { orderId } })
}

// /** 获取原木出库信息 */
// export function getWoodOutOfInfo(orderId: number) {
//   return request.get<BaseResponse>(`production/wood-out-of-info/${orderId}`)
// }

/** 获取原木出库信息 */
export function getWoodOutOfInfo(orderId: number) {
  return c6Request.get<C6BaseResponse>(`backstage/production/getWoodOutofInfo`, { params: { orderId } })
}

// /** 获取成品入库信息 */
// export function getProductIntoInfo(orderId: number) {
//   return request.get<BaseResponse>(`production/product-into-info/${orderId}`)
// }

/** 获取成品入库信息 */
export function getProductIntoInfo(orderId: number) {
  return c6Request.get<C6BaseResponse>(`backstage/production/getProductIntoInfo`, { params: { orderId } })
}

// /** 获取成品出库信息 */
// export function getProductOutofInfo(orderId: number) {
//   return request.get<BaseResponse>(`production/productoutof-info/${orderId}`)
// }

/** 获取成品出库信息 */
export function getProductOutofInfo(orderId: number) {
  return c6Request.get<C6BaseResponse>(`backstage/production/getProductOutofInfo`, { params: { orderId } })
}

// /** 获取生产耗电信息 */
// export function getPowerConsumeInfo(orderId: number) {
//   return request.get<BaseResponse>(`production/power-consume-info/${orderId}`)
// }

/** 获取生产耗电信息 */
export function getPowerConsumeInfo(orderId: number) {
  return c6Request.get<C6BaseResponse>(`backstage/production/getPowerConsumeInfo`, { params: { orderId } })
}

/** 获取旋切机收款信息 */
export function getXqjProceedsInfo(orderId: number) {
  return c6Request.get<C6BaseResponse>(`backstage/production/getXqjProceedsInfo`, { params: { orderId } })
}

// /** 获取旋切机支付款项信息 */
// export function getXqjFundsPayInfo(orderId: number) {
//   return request.get<BaseResponse>(`production/xqj-funds-pay-info/${orderId}`)
// }

/** 获取旋切机支付款项信息 */
export function getXqjFundsPayInfo(orderId: number) {
  return c6Request.get<C6BaseResponse>(`backstage/production/getXqjzFundsInfo`, { params: { orderId } })
}

// /** 获取发票信息 */
// export function getInvoiceInfo(orderId: number) {
//   return request.get<BaseResponse>(`production/invoice-info/${orderId}`)
// }

/** 获取开票信息 */
export function getInvoiceInfo(orderId: number) {
  return c6Request.get<C6BaseResponse>(`backstage/production/getInvoiceInfo`, { params: { orderId } })
}

/** 生成生产数据 */
export function createProductionData(orderId: number) {
  return request.post<BaseResponse>(`production/create-production-data-by-order-id/${orderId}`)
}

/** 生成生产数据（批量） */
export function createProductionDataByList(data: number[]) {
  return request.post<BaseResponse>(`production/create-production-data-by-list`, data)
}

// /** 获取生产配置 */
// export function getProductSetting() {
//   return request.get<BaseResponse>(`production/production-setting-list`)
// }

/** 获取生产配置 */
export function getProductSetting() {
  return c6Request.get<C6BaseResponse>(`backstage/production/getProductSetting`)
}

// /** 更新生产配置 */
// export function updateProductSetting(data: AnyObject) {
//   return request.post<BaseResponse>(`production/update-production-setting`, data)
// }

/** 更新生产配置 */
export function updateProductSetting(data: AnyObject) {
  return c6Request.post<C6BaseResponse>(`backstage/production/updateProductSetting`, data)
}

// /** 查询生产数据列表 */
// export function getProductionList(params: PageRequest<ProductionListQueryParams>) {
//   return request.get<BaseResponse>(`production/load-production-list`, { params })
// }

/** 查询生产数据列表 */
export function getProductionList(params: C6PageRequest<ProductionListQueryParams>) {
  return c6Request.get<C6BaseResponse<C6PageResponse>>(`backstage/production/getProductionPage`, { params })
}

// /** 查询存货数量金额账 */
// export function getStockNumList(params: PageRequest<StockNumListQueryParams>) {
//   return request.get<BaseResponse>(`production/load-stock-num-list`, { params })
// }

/** 查询存货数量金额账 */
export function getStockNumList(params: StockNumListQueryParams) {
  return c6Request.get<C6BaseResponse>(`backstage/production/getStockNumList`, { params })
}

// /** 生产完成(生产数据锁定) */
// export function updateProductionFinishLock(data: AnyObject) {
//   return request.post<BaseResponse>(`production/production-finish`, data)
// }

/** 生产完成(生产数据锁定) */
export function updateProductionFinishLock(data: number[]) {
  return c6Request.post<C6BaseResponse>(`backstage/production/finishProduction`, data)
}

export interface ProductionListQueryParams {
  /** 订单号 */
  orderNo?: string,
  /** 旋切机主名 */
  xqjzName?: string,
  /** 胶合板厂名 */
  jhbcName?: string,
  /** 下单开始时间 */
  orderTimeStart?: Date,
  /** 下单结束时间 */
  orderTimeEnd?: Date,
  /** 支付状态 1未支付 2已支付 */
  payStatus?: number,
  /** 开票状态(状态用","(逗号)分隔) */
  invoiceStatusStr?: string,
  /** 是否导出 1是 0否 */
  isExport?: number,
  /** 开票开始时间 */
  invoiceTimeStart?: Date,
  /** 开票结束时间 */
  invoiceTimeEnd?: Date,
  /** 销售开始时间 */
  sellTimeStart?: Date,
  /** 销售结束时间 */
  sellTimeEnd?: Date,
  /** 生产数据（1查询生产数据，2查询没有生产数据，null查询全部数据） */
  dataType?: number | null
  /* 区域编号，如 450000 */
  areaCode?: string,
  /** 支付公司名称 */
  platformAccountName?: string
}

export interface StockNumListQueryParams {
  startTime?: Date,
  endTime?: Date
}